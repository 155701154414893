// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-templates-about-page-js": () => import("./../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-motivational-quotes-js": () => import("./../src/templates/motivational-quotes.js" /* webpackChunkName: "component---src-templates-motivational-quotes-js" */),
  "component---src-templates-quote-js": () => import("./../src/templates/quote.js" /* webpackChunkName: "component---src-templates-quote-js" */),
  "component---src-templates-thank-you-js": () => import("./../src/templates/thank-you.js" /* webpackChunkName: "component---src-templates-thank-you-js" */)
}

